<!-- // earl 2021-09-06 -->
<template>
    <div class="manage_setting message-section-top">
        <div class="search_box">
            <el-input v-model="formData.data.search_str" tpye="text" placeholder="搜索人员" @input='searchBtn(searchValue)'>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div>
        <el-table ref="multipleTable" :data="dataList" tooltip-effect="dark" style="width: 100%" height="450">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="nick_name" label="昵称"></el-table-column>
            <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                    <span>{{scope.row.sex=='0'? '女': '男'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthday" label="生日"> </el-table-column>
            <el-table-column prop="mobile" label="联系方式"> </el-table-column>
            <el-table-column prop="in_group_date" label="入群日期"> </el-table-column>
            <el-table-column prop="member_age" label="入会年限"> </el-table-column>
            <el-table-column prop="level_name" label="等级"> </el-table-column>
            <el-table-column prop="last_login_date" label="最近登录日期" width="160"> </el-table-column>
            <el-table-column prop="active_times" label="活跃时间"> </el-table-column>
            <el-table-column prop="consume_amt_sum" label="消费累计金额" width="160"></el-table-column>
            <el-table-column prop="last_consume_date" label="最近消费日期" width="160"></el-table-column>
            <el-table-column prop="last_consume_amt" label="最近消费金额" width="160"></el-table-column>
            <el-table-column prop="join_action_num" label="参与活动次数" width="160"></el-table-column>
        </el-table>
    </div>
</template>
<script>
import {query_member_file_list} from '../../../api/store'
export default {
    data() {
        return {
            formData: {data:{ent_id: this.$store.state.ent_id, search_str: '', page_size: '10', page_index: '1'}},
            dataList: [],
            searchValue: ''
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        async getDataList() {
            let params = this.formData
            let res = await query_member_file_list(params)
            if(res && res.code==200) {
                this.dataList = res.body.data.list
                // console.log('this.dataList', this.dataList)
            } else {
                this.$message({message: res.msg, type: 'error'})
            }
        },
        searchBtn(v) {
            this.getDataList()
        }
    }
}
</script>
<style scoped lang="scss">
.manage_setting{ padding: 20px 30px; background: #fff; 
    .search_box{ margin-bottom: 30px; 
        .el-input{ width: 260px; }
    }
}
</style>